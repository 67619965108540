// import libraries
import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

// import icons
import { FaTimes } from "react-icons/fa";

// import CSS file for styling
import "./pricingmodal.css";

// import components
import PricingComponent from "../../PaymentComponent/PricingComponent";

const Pricingmodal = ({ isOpen, toggleModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      size="xl"
      centered
      className="custom-pricing-modal"
    >
      <ModalHeader toggle={toggleModal} className="custom-modal-header">
        <FaTimes className="close-icon" onClick={toggleModal} />
      </ModalHeader>
      <ModalBody>
        <div className="pricing-content">
          {" "}
          <PricingComponent />{" "}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Pricingmodal;

