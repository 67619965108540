import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import DocDivelog from "../../assets/images/DocDive_logo.jpg";

import LeagueSpartanBold from "../../assets/fonts/LeagueSpartan-SemiBold.ttf";
import LeagueSpartanRegular from "../../assets/fonts/LeagueSpartan-Regular.ttf";
import { format } from "date-fns";

Font.register({
  family: "League Spartan",
  fonts: [
    {
      src: LeagueSpartanBold,
      fontWeight: 700,
    },
    {
      src: LeagueSpartanRegular,
      fontWeight: 400,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  header: {
    flexDirection: "row",
    justifyContent: "start",
    marginBottom: 20,
  },
  logo: {
    width: 145,
    height: 25,
    marginBottom: 5,
  },
  pdfdetails: {
    fontSize: 10,
    color: "#9A9595",
    fontStyle: "normal",
    fontWeight: 900,
    marginBottom: 5,
  },
  lineSeparator: {
    border: "1px solid #9A9595",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  endText: {
    fontSize: 12,
    color: "#9A9595",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: 5,
    textAlign: "center",
  },
});

const GenerateAnnotationPdf = ({
  dataList,
  documentName,
  annotationType,
  displayName,
}) => {
  const getDate = () => {
    return format(new Date(), "yyyy-MM-dd HH:mm:ss a");
  };

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image source={DocDivelog} style={styles.logo} />
        </View>
        <Text style={styles.pdfdetails}>Document name : {documentName}</Text>
        <Text style={styles.pdfdetails}>Export type : {displayName}</Text>
        <Text style={styles.pdfdetails}>Export time : {getDate()}</Text>
        <View style={styles.lineSeparator} />
        {renderData(dataList, annotationType)}
        <View style={styles.lineSeparator} />
      </Page>
    </Document>
  );
};

const renderData = (data, type) => {
  switch (type) {
    case "timeline":
      return getTimelineDataList(data);
    case "summary":
      return getSummaryDataList(data);
    case "headings":
      return getHeadingDataList(data);
    case "topics":
      return getTopicsList(data);
    case "citations":
      return getCitationsList(data);
    case "cited_examples":
      return getCitedExampleList(data);
    case "ner":
      return getNERDataList(data);
    case "info_snippets":
      return getInfoSnippets(data);
    case "financial_info":
      return getBusinessInfo(data);
    case "educational_info":
      return getBusinessInfo(data);
    case "editorial_info":
      return getBusinessInfo(data);
    case "legal_info":
      return getBusinessInfo(data);
    default:
      return <Text>No Content</Text>;
  }
};

//Timeline - ✔
const getTimelineDataList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <View
          key={index}
          style={{
            display: "flex",
            marginBottom: 8,
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 5,
            flexWrap: "wrap",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              fontFamily: "League Spartan",
              fontWeight: 700,
              color: "#4F4B4B",
            }}
          >
            {"\u2022 "} {item?.time}:{" "}
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "League Spartan",
              color: "#4F4B4B",
              flex: 1,
            }}
          >
            {item?.summary}
          </Text>
        </View>
      );
    })
  );
};

// Key Info - ✔
const getSummaryDataList = (data) => {
  return (
    <View
      style={{
        display: "flex",
        marginBottom: 8,
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 5,
        flexWrap: "wrap",
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontFamily: "League Spartan",
          fontWeight: 400,
          color: "#4F4B4B",
        }}
      >
        {data ?? ""}
      </Text>
    </View>
  );
};

// Label brief - ✔
const getHeadingDataList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <View
          key={index}
          style={{
            display: "flex",
            marginBottom: 10,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              fontFamily: "League Spartan",
              fontWeight: 700,
              color: "#4F4B4B",
              marginBottom: 2,
            }}
          >
            {item?.heading}
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "League Spartan",
              color: "#4F4B4B",
            }}
          >
            {item?.summary}
          </Text>
        </View>
      );
    })
  );
};

//Topics - ✔
const getTopicsList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <View
          key={index}
          style={{
            display: "flex",
            marginBottom: 10,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              fontFamily: "League Spartan",
              fontWeight: 700,
              color: "#4F4B4B",
              marginBottom: 2,
            }}
          >
            {item?.topic}
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "League Spartan",
              color: "#4F4B4B",
            }}
          >
            {item?.description}
          </Text>
        </View>
      );
    })
  );
};

//Citations - ✔
const getCitationsList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <View
          key={index}
          style={{
            display: "flex",
            marginBottom: 10,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              fontFamily: "League Spartan",
              fontWeight: 700,
              color: "#4F4B4B",
              marginBottom: 2,
            }}
          >
            {item[`D${index + 1}`]}
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "League Spartan",
              color: "#4F4B4B",
            }}
          >
            {item[`S${index + 1}`]}
          </Text>
        </View>
      );
    })
  );
};

//Cited Examples - ✔
const getCitedExampleList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <View
          key={index}
          style={{
            display: "flex",
            marginBottom: 10,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              fontFamily: "League Spartan",
              fontWeight: 700,
              color: "#4F4B4B",
              marginBottom: 2,
            }}
          >
            {item?.term}
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "League Spartan",
              color: "#4F4B4B",
            }}
          >
            {item?.example}
          </Text>
        </View>
      );
    })
  );
};

//Named Entities - ✔
const getNERDataList = (data) => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        gap: "15px",
        flexWrap: "wrap",
      }}
    >
      {data &&
        data?.map((item, index) => {
          return (
            <View
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px 5px",
                borderRadius: "5px",
                borderColor: "#CAC8C8",
                borderWidth: "1px",
                borderStyle: "solid",
                backgroundColor: "#fff",
                width: "100%",
                height: "auto",
              }}
            >
              <Text
                style={{
                  color: "#4F4B4B",
                  fontSize: "14px",
                  fontWeight: 700,
                  padding: "10px 5px 4px 5px",
                  fontFamily: "League Spartan",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "#CAC8C8",
                  marginBottom: 3,
                }}
              >
                {item.display_name}
              </Text>
              <View
                style={{
                  paddingTop: "5px",
                  paddingLeft: "5px",
                }}
              >
                {item?.entities?.map((subItem, subIndex) => {
                  return (
                    <Text
                      key={subIndex}
                      style={{
                        color: "#4F4B4B",
                        fontSize: "14px",
                        fontWeight: 400,
                        fontFamily: "League Spartan",
                        lineHeight: 1.5,
                      }}
                    >
                      {subItem}
                    </Text>
                  );
                })}
              </View>
            </View>
          );
        })}
    </View>
  );
};

//Info Snippets - ✔
const getInfoSnippets = (data) => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        gap: "15px",
        flexWrap: "wrap",
      }}
    >
      {data &&
        Object.keys(data).map((key, index) => {
          if (data[key]?.length === 0) return null;
          return (
            <View
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px 5px",
                borderRadius: "5px",
                borderColor: "#CAC8C8",
                borderWidth: "1px",
                borderStyle: "solid",
                backgroundColor: "#fff",
                width: "100%",
                height: "auto",
              }}
            >
              <Text
                style={{
                  color: "#4F4B4B",
                  fontSize: "14px",
                  fontWeight: 700,
                  padding: "10px 5px 4px 5px",
                  fontFamily: "League Spartan",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "#CAC8C8",
                  marginBottom: 3,
                }}
              >
                {key}{" "}
              </Text>

              <View
                style={{
                  paddingTop: "5px",
                  paddingLeft: "5px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                {data[key]?.map((item, subIndex) => {
                  return (
                    <Text
                      key={subIndex}
                      style={{
                        color: "#4F4B4B",
                        fontSize: "14px",
                        fontWeight: 400,
                        fontFamily: "League Spartan",
                        lineHeight: 1.5,
                        marginBottom: 3,
                        overflow: "hidden",
                        whiteSpace: "normal",
                      }}
                    >
                      {item}
                    </Text>
                  );
                })}
              </View>
            </View>
          );
        })}
    </View>
  );
};

const getBusinessInfo = (data) => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        gap: "15px",
        flexWrap: "wrap",
      }}
    >
      {data &&
        Object.keys(data).map((key, index) => {
          if (data[key]?.length === 0) return null;
          return (
            <View
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px 5px",
                borderRadius: "5px",
                borderColor: "#CAC8C8",
                borderWidth: "1px",
                borderStyle: "solid",
                backgroundColor: "#fff",
                width: "100%",
                height: "auto",
              }}
            >
              <Text
                style={{
                  color: "#4F4B4B",
                  fontSize: "14px",
                  fontWeight: 700,
                  padding: "10px 5px 4px 5px",
                  fontFamily: "League Spartan",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "#CAC8C8",
                  marginBottom: 3,
                }}
              >
                {key}{" "}
              </Text>

              <View
                style={{
                  paddingTop: "5px",
                  paddingLeft: "5px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                {data[key]?.map((item, subIndex) => {
                  return (
                    <View key={subIndex} style={{ marginBottom: 8 }}>
                      <Text
                        style={{
                          color: "#4F4B4B",
                          fontSize: "12px",
                          fontWeight: 700,
                          fontFamily: "League Spartan",
                          lineHeight: 1,
                          marginBottom: 1,
                          overflow: "hidden",
                          whiteSpace: "normal",
                        }}
                      >
                        {item?.item}
                      </Text>
                      <Text
                        style={{
                          color: "#4F4B4B",
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "League Spartan",
                          lineHeight: 1,
                          overflow: "hidden",
                          whiteSpace: "normal",
                        }}
                      >
                        {item?.term_description}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          );
        })}
    </View>
  );
};

export default GenerateAnnotationPdf;
