import axios from "axios";
import { BASE_URL } from "../Config/Config";

const ChatDataObject = {
  ChatHistory_data: async (document_id, chat_id, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/api/chat-history?document_id=${document_id}&chat_id=${chat_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },

  ChatList_data: async (document_id) => {
    return await axios({
      method: "GET",
      url: `${BASE_URL}/api/chats?document_id=${document_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  DeleteChatHistory_data: async (document_id, chat_id, callback) => {
    axios({
      method: "DELETE",
      url: `${BASE_URL}/api/chat-history?document_id=${document_id}&chat_id=${chat_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },

  Chat_data: async (data) => {
    const res = axios({
      method: "POST",
      url: `${BASE_URL}/api/chats`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    });
    return res;
  },
};

export default ChatDataObject;
