import { AiFillEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";

// import CSS file for styling
import "./sidebarItem.css";
import React, { useEffect, useState } from "react";
import authDataObject from "../../services/Auth_Controller/Auth_Controller";
import { Spinner } from "reactstrap";
import DeleteConfirmModal from "../Commoncomponent/DeleteConfirmModal/DeleteConfirmModal";
import { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import ErrorAlert from "../Commoncomponent/ErrorAlert/ErrorAlert";

const SidebarItem = ({
  Icon,
  file,
  active,
  selected,
  onClick,
  isFileOpened,
  onIdClick,
  onItemClick,
  isDeleting,
  setIsDeleting,
}) => {
  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [showError, setShowError] = useState({
    isOpen: false,
    message: "",
  });

  const queryClient = useQueryClient();

  //delete mutation
  const mutation = useMutation(authDataObject.deleteDocument, {
    onMutate: () => {
      setIsDeleting(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("file-list");
      // if the selected file deleted then set null as selected file
      if (selected) {
        onIdClick(null);
        onItemClick(null);
      }
    },
    onError: () => {
      setShowError({
        isOpen: true,
        message: "Failed to delete the file",
      });
      setIsDeleting(false);
    },
  });

  // to avoid display input filed when sidebar collapsed
  useEffect(() => {
    if (!active) {
      setIsEditing(false);
    }
  }, [active]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // gap: active && "10px",
        }}
      >
        {!isFileOpened ? (
          <span
            style={{
              borderRadius: "50%",
              padding: 4,
              backgroundColor: "blue",
              marginLeft: 4,
              marginRight: 3,
            }}
          ></span>
        ) : (
          <span
            style={{
              padding: 4,
              marginLeft: 4,
              marginRight: 3,
            }}
          ></span>
        )}
        <div
          className="container-side-bar-item"
          style={{
            backgroundColor: selected && "#E9E1E1",
            // gap: active ? "10px" : "0px",
            // width: active ? "100%" : "fit-content",
            // padding: !active && "5px",
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          {isEditing && active ? (
            <InputFiled
              selectedFileId={file?.document_id}
              file_name={file?.file_name}
              onClose={() => setIsEditing(false)}
              Icon={Icon}
            />
          ) : (
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontSize: 14,
                height: 35,
              }}
              onClick={() => {
                if (!isEditing) onClick();
              }}
            >
              {isDeleting && file.document_id === mutation.variables ? (
                <span>
                  <Spinner
                    size={"sm"}
                    color="secondary"
                    className="m-1 spinner-loader"
                  />
                </span>
              ) : (
                Icon && <img src={Icon} alt="icon" />
              )}

              <span
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontSize: 14,
                }}
                // onClick={onClick}
              >
                {file?.file_name}
              </span>
            </span>
          )}
          {active && hover && !isEditing ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 2,
                marginLeft: "auto",
              }}
            >
              {isDeleting && file.document_id === mutation.variables ? (
                <Spinner
                  size={"sm"}
                  color="secondary"
                  className="m-1 spinner-loader"
                />
              ) : (
                <div>
                  <AiFillEdit
                    fontSize={18}
                    className="action-icon"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  />
                  <MdDeleteForever
                    fontSize={18}
                    className="action-icon"
                    onClick={() => {
                      setIsOpenDeleteDialog(true);
                    }}
                    style={{
                      marginLeft: 5,
                    }}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      {isOpenDeleteDialog && (
        <DeleteConfirmModal
          isOpen={isOpenDeleteDialog}
          handleClose={() => setIsOpenDeleteDialog(false)}
          handleConfirm={() => mutation.mutate(file?.document_id)}
        />
      )}
      {showError && (
        <ErrorAlert
          isOpen={showError.isOpen}
          handleClose={() => setShowError(false)}
          message={showError.message}
        />
      )}
    </>
  );
};

export default SidebarItem;

const InputFiled = ({ selectedFileId, file_name, onClose }) => {
  const [inputValue, setInputValue] = useState(
    file_name.split(".").slice(0, -1).join(".")
  );
  const [showError, setShowError] = useState({
    isOpen: false,
    message: "",
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(authDataObject.patchFileName, {
    onSuccess: () => {
      queryClient.invalidateQueries("file-list");
      onClose();
    },
    onError: () => {
      setShowError({
        isOpen: true,
        message: "Failed to update the file name",
      });
    },
  });

  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element is outside the input container
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div class="input-container" ref={inputRef}>
      <input
        type="text"
        class="input-field"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            mutation.mutate({
              document_id: selectedFileId,
              name: "document_name",
              value: `${inputValue}.${file_name.split(".").pop()}`,
            });
          }
        }}
        disabled={mutation.isLoading}
      />
      {mutation.isLoading ? (
        <Spinner size={"sm"} color="secondary" className="m-1 spinner-loader" />
      ) : (
        <div className="action-button-container">
          <AiOutlineCheck
            fontSize={18}
            className="action-icon"
            onClick={() => {
              mutation.mutate({
                document_id: selectedFileId,
                name: "document_name",
                value: `${inputValue}.${file_name.split(".").pop()}`,
              });
            }}
          />
          <AiOutlineClose
            fontSize={18}
            className="action-icon"
            onClick={() => {
              onClose();
            }}
          />
        </div>
      )}
      {showError && (
        <ErrorAlert
          isOpen={showError.isOpen}
          handleClose={() => setShowError(false)}
          message={showError.message}
        />
      )}
    </div>
  );
};
