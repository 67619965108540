import React from "react";
import { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
} from "reactstrap";
import ExoprtIcon from "../assets/images/export_file_icon.svg";
import GeneratePdf from "../components/Commoncomponent/GeneratePdf/GeneratePdf";
import GenerateAnnotationPdf from "../InsightPulse/PulseModal/GenerateAnnotationPdf";
import { useEffect } from "react";
import authDataObject from "../services/Auth_Controller/Auth_Controller";
import { pdf } from "@react-pdf/renderer";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const ExportDropDown = ({
  selectedId,
  isEnable,
  chat_conversation,
  selectedFileName,
  chattype,
  annotations,
  annotationsBusiness,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [isOpenDownloadModal, setOpenDownloadModal] = useState(false);
  const [displayName, setDisplayName] = useState("");

  const [openAccordion, setOpenAccordion] = useState("1");
  const toggleAccordion = (id) => {
    console.log(id, "id");
    if (openAccordion === id) {
      setOpenAccordion();
    } else {
      setOpenAccordion(id);
    }
  };

  return (
    <>
      <Dropdown
        className="dropdown-container"
        isOpen={isOpen}
        toggle={() => toggleDropdown()}
      >
        <DropdownToggle
          caret
          className="icon-text-container"
          disabled={!isEnable}
          style={{
            cursor: isEnable ? "pointer" : "default",
            gap: isOpen ? "27px" : "8px",
          }}
        >
          <img src={ExoprtIcon} alt="export icon" />
          <span className="text">{"Export"}</span>
        </DropdownToggle>
        <DropdownMenu className="drop-down-menu-style">
          <DropdownItem className="disable-item" disabled>
            Export options
          </DropdownItem>
          <div className="drop-down-item-style">
            <GeneratePdf
              chatData={chat_conversation}
              pdfname={selectedFileName}
              chatmode={chattype}
              name="Chat history"
            />
          </div>
          <Accordion
            open={openAccordion}
            toggle={(val) => toggleAccordion(val)}
          >
            <AccordionItem>
              <AccordionHeader
                targetId="1"
                style={{
                  padding: "6px 5px",
                  width: "100%",
                  fontSize: "14px",
                  marginBottom: 0,
                }}
              >
                Essentials information{" "}
                {openAccordion === "1" ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </AccordionHeader>
              {annotations &&
                annotations?.map(
                  (item, index) =>
                    item.status === "completed" && (
                      <AccordionBody
                        key={index}
                        accordionId="1"
                        style={{
                          overflow: "hidden",
                        }}
                      >
                        <div
                          className="drop-down-item-style"
                          style={{
                            margin: 4,
                            cursor: "pointer",
                            fontSize: 14,
                            fontWeight: 500,
                            padding: "4px 15px",
                          }}
                          onClick={() => {
                            if (item.status === "completed") {
                              setSelectedAnnotation(item.annotation_type);
                              console.log(
                                item.annotation_type,
                                "item.annotation_type"
                              );
                              setOpenDownloadModal(true);
                              setDisplayName(
                                item.display_name.replace(/_/g, " ")
                              );
                            }
                          }}
                        >
                          {item.display_name?.replace(/_/g, " ")}
                        </div>
                      </AccordionBody>
                    )
                )}
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="2"
                style={{
                  padding: "6px 5px",
                  width: "100%",
                  fontSize: "14px",
                  marginBottom: 0,
                }}
              >
                Business Insights{" "}
                {openAccordion === "2" ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </AccordionHeader>
              {annotationsBusiness &&
                annotationsBusiness?.map(
                  (item, index) =>
                    item.status === "completed" && (
                      <AccordionBody
                        key={index}
                        accordionId="2"
                        style={{
                          overflow: "hidden",
                        }}
                      >
                        <div
                          className="drop-down-item-style"
                          style={{
                            margin: 4,
                            cursor: "pointer",
                            fontSize: 14,
                            fontWeight: 500,
                            padding: "4px 10px",
                          }}
                          onClick={() => {
                            if (item.status === "completed") {
                              setSelectedAnnotation(item.annotation_type);
                              console.log(
                                item.annotation_type,
                                "item.annotation_type"
                              );
                              setOpenDownloadModal(true);
                              setDisplayName(
                                item.display_name.replace(/_/g, " ")
                              );
                            }
                          }}
                        >
                          {item.display_name?.replace(/_/g, " ")}
                        </div>
                      </AccordionBody>
                    )
                )}
            </AccordionItem>
          </Accordion>
        </DropdownMenu>
      </Dropdown>
      {isOpenDownloadModal && (
        <DownloadModal
          selectedId={selectedId}
          selectedAnnotation={selectedAnnotation}
          handleClose={() => {
            setOpenDownloadModal(false);
            setSelectedAnnotation(null);
            setIsOpen(false);
          }}
          selectedFileName={selectedFileName}
          displayName={displayName}
        />
      )}
    </>
  );
};

export default ExportDropDown;

const DownloadModal = ({
  selectedId,
  selectedAnnotation,
  handleClose,
  selectedFileName,
  displayName,
}) => {
  const [dataList, setDataList] = useState([]);
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pdfGenerated) {
      downloadPdf();
    }
  }, [pdfGenerated]);

  const downloadPdf = async () => {
    if (
      selectedAnnotation === "ner" ||
      selectedAnnotation === "info_snippets" ||
      selectedAnnotation === "headings"
    ) {
      let blobPdf = await pdf(
        GenerateAnnotationPdf({
          dataList,
          documentName: selectedFileName,
          annotationType: selectedAnnotation,
          displayName: displayName,
        })
      ).toBlob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blobPdf);
      console.log(blobPdf, "blobPdf");
      link.download = `${selectedFileName
        ?.split(".")
        .slice(0, -1)
        .join(".")}-${displayName.replace(/ /g, "_")}.pdf`;
      link.click();
      setIsLoading(false);
      handleClose(); // Optionally close the modal after download
    } else {
      const res = await authDataObject.exportPDF({
        document_id: selectedId,
        annotation_type: selectedAnnotation,
      });
      if (res.status === 200) {
        setIsLoading(false);
      }
      let url = res.data?.details?.presigned_url;
      const link = document.createElement("a");
      link.href = url;
      link.download = `${selectedFileName
        ?.split(".")
        .slice(0, -1)
        .join(".")}-${displayName.replace(/ /g, "_")}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false);
      handleClose(); // Optionally close the modal after download
    }
  };

  const fetchData = async () => {
    if (
      selectedAnnotation === "ner" ||
      selectedAnnotation === "info_snippets" ||
      selectedAnnotation === "headings"
    ) {
      setIsLoading(true);
      const result = await authDataObject.getAnnotationData({
        document_id: selectedId,
        annotation_type: selectedAnnotation,
      });
      if (result?.status === 200) {
        setDataList(filterData(result?.data));
        setPdfGenerated(true);
        setIsLoading(false);
      }
    } else {
      console.log(
        selectedId,
        selectedAnnotation,
        "selectedId, selectedAnnotation"
      );
      setIsLoading(true);
      setPdfGenerated(true);
    }
  };

  return isLoading ? (
    <div
      style={{
        height: "100vh",
        width: " 100%",
        position: "fixed",
        top: 65,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: "1050",
        background: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(1.5px)",
      }}
    >
      <Spinner
        size={"xl"}
        className="m-1 spinner-loader"
        color="secondary"
        style={{
          width: "50px",
          height: "50px",
          zIndex: "102",
        }}
      />
      <span
        style={{
          color: "#6c757d",
        }}
      >
        Generating...
      </span>
    </div>
  ) : null;
};

const filterData = (data) => {
  switch (data?.details?.annotation_type) {
    case "timeline":
      return data.details.response;
    case "summary":
      return data.details.response?.summary;
    case "headings":
      return data.details.response;
    case "ner":
      return data?.details?.response;
    case "citations":
      return data?.details?.response?.citations;
    case "cited_examples":
      return data?.details?.response;
    case "topics":
      return data?.details?.response;
    case "info_snippets":
      return data?.details?.response;
    case "financial_info":
      return data?.details?.response;
    case "educational_info":
      return data?.details?.response;
    case "editorial_info":
      return data?.details?.response;
    case "legal_info":
      return data?.details?.response;
    default:
      console.log("default", data);
  }
};
