import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Modal, ModalBody } from "reactstrap";
import "./ProfileModal.css";
import ProfileDetails from "./components/ProfileDetails";
import BillingPreferences from "./components/BillingPreference";
import Usage from "./components/Usage";
import BillingHistory from "./components/BillingHistory";
import PaymentMethods from "./components/PaymentMethods";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ProfileModal = ({ isOpen, handleClose }) => {
  const [title, setTitle] = useState("Details");

  const handleTitle = (title) => {
    setTitle(title);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      size="xl"
      centered
      backdrop
      fade
    >
      <ModalBody style={{ padding: 0 }}>
        <div className="bodyconatiner">
          <div className="header">
            <button className="close-button" onClick={() => handleClose(false)}>
              <FaTimes
                className="close-button-icon"
                style={{ width: "14px" }}
              />
            </button>

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: 0.2,
                position: "relative",
              }}
            >
              <span
                className={
                  "headertitle " + (title === "Details" ? "selected" : "")
                }
                style={{
                  fontSize: title == "Details" ? "17px" : "",
                  fontWeight: title == "Details" ? "700" : "",
                }}
                onClick={() => handleTitle("Details")}
              >
                Details
              </span>
              <span
                className={
                  "headertitle " + (title === "Usage" ? "selected" : "")
                }
                style={{
                  fontSize: title == "Usage" ? "17px" : "",
                  fontWeight: title == "Usage" ? "700" : "",
                }}
                onClick={() => handleTitle("Usage")}
              >
                Usage
              </span>

              <span
                className={
                  "headertitle " +
                  (title === "Billing_history" ? "selected" : "")
                }
                style={{
                  fontSize: title == "Billing_history" ? "17px" : "",
                  fontWeight: title == "Billing_history" ? "700" : "",
                }}
                onClick={() => handleTitle("Billing_history")}
              >
                Billing history
              </span>

              <span
                className={
                  "headertitle " +
                  (title === "Payment_methods" ? "selected" : "")
                }
                style={{
                  fontSize: title == "Payment_methods" ? "17px" : "",
                  fontWeight: title == "Payment_methods" ? "700" : "",
                }}
                onClick={() => handleTitle("Payment_methods")}
              >
                Payment methods
              </span>

              <span
                className={
                  "headertitle " +
                  (title === "Billing_preferences" ? "selected" : "")
                }
                style={{
                  fontSize: title == "Billing_preferences" ? "17px" : "",
                  fontWeight: title == "Billing_preferences" ? "700" : "",
                }}
                onClick={() => handleTitle("Billing_preferences")}
              >
                Billing preferences
              </span>
            </div>
          </div>
          <div className="bobdydiv">
            {title === "Details" && <ProfileDetails />}
            {title === "Usage" && <Usage />}
            {title === "Billing_history" && <BillingHistory />}
            {title === "Payment_methods" && <PaymentMethods />}
            {title === "Billing_preferences" && <BillingPreferences />}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ProfileModal;
