// import libraries
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import Swal from "sweetalert2";

// import icons
import { FaTimes } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// import CSS file for styling
import "./signinmodal.css";

// import components
import GoogleLoginComponent from "../../AuthComponent/GoogleLogin";

// import controller object for api calling
import authDataObject from "../../../services/Auth_Controller/Auth_Controller";
import { useMutation } from "react-query";
import ConfirmationAlertModal from "../../ProfileModal/components/ConfirmationAlertModal";
import ErrorAlert from "../ErrorAlert/ErrorAlert";

const Signinmodal = ({
  isOpen,
  toggleModal,
  onContinueWithEmailClick,
  onStatusTrue,
  onVerifyLinkClick,
}) => {
  // Define state variables
  const [formState, setFormState] = useState("enterEmail"); // Initial state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loderButton, setLoderButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState({
    status: false,
    message: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // Set the initial state of the form when modal opens
    setFormState("enterEmail");
    setEmail("");
    setPassword("");
    setEmailError("");
    setPasswordError("");
  }, [isOpen]);

  // Handle the "Continue with Email" button click
  const handleContinueWithEmail = (event) => {
    event.preventDefault(); // Prevent the form from submitting

    if (formState === "enterEmail") {
      if (!email) {
        setEmailError("Please enter an email address");
      } else if (!isValidEmail(email)) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
        // Change state to show password field
        setFormState("enterEmailAndPassword");
      }
    } else if (formState === "enterEmailAndPassword") {
      if (!password) {
        setPasswordError("Please enter a password");
      } else {
        setLoderButton(true);
        setPasswordError("");
        const body = {
          username: email.trim(),
          password: password.trim(),
        };
        // Perform some authentication operations
        authDataObject.AdminToken(body, (result) => {
          const button = document.querySelector(".Emailbutton-container");
          button.disabled = false;
          if (result?.data?.status === "success") {
            setLoderButton(false);
            localStorage.setItem("UserName", result?.data?.details?.user_alias);
            localStorage.setItem("UserEmail", result?.data?.details?.username);
            if (result?.data?.details?.icon) {
              localStorage.setItem(
                "UserUploadedImage",
                result?.data?.details?.icon
              );
            }
            localStorage.setItem("token", result?.data?.details?.access_token);
            onContinueWithEmailClick();
          } else {
            // Handle authentication failure
            if (result?.data?.message === "User not found") {
              authDataObject.AdminSignUp(body, (result) => {
                if (
                  result?.data?.status === "success" &&
                  result?.data?.message === "user created"
                ) {
                  setLoderButton(false);
                  onContinueWithEmailClick();
                  onVerifyLinkClick(true);
                } else {
                  setLoderButton(false);
                  // Handle authentication failure
                  onVerifyLinkClick(false);
                }
              });
            } else if (
              result?.data?.message === "invalid username or password"
            ) {
              setLoderButton(false);
              setPasswordError(result?.data?.message);
            } else if (result?.data?.message === "email verification pending") {
              setLoderButton(false);
              onVerifyLinkClick(true);
            } else if (
              result?.data?.message ===
              "This user is registered/signed-in with Google. Please use Google Sign In"
            ) {
              setLoderButton(false);
              // setTimeout(() => {
              //   onContinueWithEmailClick();
              // }, 1500);
              setShowError({
                status: true,
                message: result?.data?.message,
              });
            }
          }
        });
      }
    }
  };
  // Function to validate email format
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // CSS classes for displaying errors
  const emailErrorClass = emailError ? "error" : "";
  const passwordErrorClass = passwordError ? "error" : "";
  const handleStatusTrue = (status) => {
    onStatusTrue(status);
  };

  const [showConfirmation, setShowConfirmation] = useState({
    isResetSent: false,
    message: "",
  });
  const { mutate, isLoading } = useMutation(
    (email) => authDataObject.ForgotPassword(email),
    {
      onSuccess: (data) => {
        if (data.status === 201) {
          setShowConfirmation({
            isResetSent: true,
            message:
              "Reset link sent to your email address. Please check your inbox.",
          });
        }
      },
      onError: (error) => {
        setShowConfirmation({
          isResetSent: true,
          message: "Something went wrong. Please check your email address.",
        });
      },
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      // size="md"
      centered
      className="custom-modal"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ModalHeader toggle={toggleModal}>
        <FaTimes className="close-icon" onClick={toggleModal} />
        <div className="signin-title">
          {formState === "ForgotPassword" ? "Forgot Password" : "Sign in"}
        </div>
      </ModalHeader>
      <ModalBody>
        {formState === "ForgotPassword" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div style={{ fontSize: "14px", fontWeight: "bold", width: "70%" }}>
              Enter your email address below and we'll send you a link to reset
              your password.
            </div>
            <div style={{ width: "100%", marginTop: 25, marginBottom: 10 }}>
              <div style={{ marginTop: "10px" }}>
                <input
                  type="text"
                  placeholder="Email"
                  className={`email-style ${emailErrorClass}`}
                  name="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value.length > 0) {
                      setEmailError("");
                    }
                  }}
                  value={email}
                />
              </div>
            </div>
            <button
              className="Emailbutton-container"
              type="button"
              onClick={() => {
                mutate(email);
              }}
            >
              {isLoading ? (
                <Spinner style={{ width: "1rem", height: "1rem" }} />
              ) : (
                "Send reset link"
              )}
            </button>
            <div
              style={{
                color: "#9a9595",
                fontSize: "14px",
                fontWeight: 400,
                width: "70%",
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span> Remembered your password? </span>
              <span
                className={`forgot-pw-link ${isLoading ? "disabled" : ""}`}
                style={{ marginTop: 5 }}
                onClick={() => {
                  setFormState("enterEmail");
                }}
              >
                Click here to sign in
              </span>
            </div>
          </div>
        ) : (
          <div className="content">
            <div
              style={{
                margin: "0 15%",
                pointerEvents: loderButton ? "none" : "",
              }}
            >
              <GoogleLoginComponent
                onContinuegoogleClick={onContinueWithEmailClick}
                onStatusTrue={handleStatusTrue}
              />
            </div>
            <div className="divider">or</div>
            <form onSubmit={(e) => handleContinueWithEmail(e)}>
              <div>
                {formState === "enterEmail" ? (
                  <input
                    type="text"
                    placeholder="Email"
                    className={`email-style ${emailErrorClass}`}
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (e.target.value.length > 0) {
                        setEmailError("");
                      }
                    }}
                    value={email}
                  />
                ) : null}
                {formState === "enterEmail" && emailError ? (
                  <div className="error-message">{emailError}</div>
                ) : null}
              </div>
              {formState === "enterEmailAndPassword" ? (
                <div style={{ marginTop: "10px" }}>
                  <div className={`password-style ${passwordErrorClass}`}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="password-input"
                      placeholder="Password"
                      name="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (e.target.value.length > 0) {
                          setPasswordError("");
                        }
                      }}
                      value={password}
                    />
                    <button
                      type="button"
                      className="password-toggle"
                      onClick={() => togglePasswordVisibility()}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {passwordError ? (
                    <div className="error-message">{passwordError}</div>
                  ) : null}
                </div>
              ) : null}
              <div>
                {formState === "enterEmail" ? (
                  <button className="Emailbutton-container" type="submit">
                    {" "}
                    Continue with Email{" "}
                  </button>
                ) : (
                  <button className="Emailbutton-container" type="submit">
                    {" "}
                    {loderButton ? (
                      <Spinner style={{ width: "1rem", height: "1rem" }} />
                    ) : (
                      "Continue"
                    )}
                  </button>
                )}
              </div>
              {formState === "enterEmailAndPassword" && (
                <div>
                  <span
                    className={`forgot-pw-link ${
                      loderButton ? "disabled" : ""
                    }`}
                    onClick={() => {
                      setFormState("ForgotPassword");
                    }}
                  >
                    Forgot your password?
                  </span>
                </div>
              )}
            </form>
          </div>
        )}
      </ModalBody>
      {showConfirmation.isResetSent && (
        <ConfirmationAlertModal
          isOpen={showConfirmation.isResetSent}
          handleClose={() => {
            setShowConfirmation({
              isResetSent: false,
              message: "",
            });
            toggleModal();
          }}
          title={showConfirmation.message}
        />
      )}
      {showError && (
        <ErrorAlert
          isOpen={showError.status}
          message={showError.message}
          handleClose={() => {
            setShowError({
              status: false,
              message: "",
            });
          }}
        />
      )}
    </Modal>
  );
};

export default Signinmodal;
