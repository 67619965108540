import React from "react";
import { BsUpload } from "react-icons/bs";
import "./UploadLinkButton.css";

function UploadLinkButton({ sidebarStatus, onClick }) {
  return (
    <>
      <div className="container">
        <div className="container-flex" onClick={onClick}>
          <BsUpload className="icon" />
          <span className={"button"}>Upload a File</span>
        </div>
      </div>
      <hr className={`${sidebarStatus ? "hr" : "hr-coll"}`} />
    </>
  );
}

export default UploadLinkButton;
