// import libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SplitPane from "react-split-pane";
import Swal from "sweetalert2";

// import components
import PdfViewerComponent from "../PdfViewerComponent/PdfViewerComponent";
import PdfViewerComponentSelect from "../PdfViewerComponent/PdfViewerComponentSelect";
import ChatComponent from "../../ChatComponent/ChatComponent";
import TextViewerComponent from "../TextViewerComponent/TextViewerComponent";
import TabsComponent from "../Commoncomponent/Tabs/TabsComponent";

// import CSS file for styling
import "./index.css";

// import controller object for api calling
import authDataObject from "../../services/Auth_Controller/Auth_Controller";
import InsightPulse from "../../InsightPulse/InsightPulse";
import { useQuery } from "react-query";

const IndexContainer = styled.div`
  margin-left: ${(props) =>
    props.active && props.sidebarStatus && props.windowWidth > 770
      ? "190px"
      : props.windowWidth > 770
      ? "50px"
      : "0"};
  margin-right: ${(props) =>
    props.active && props.settingbarStatus && props.windowWidth > 770
      ? "190px"
      : props.windowWidth > 770
      ? "0"
      : "0"};

  transition: all 0.4s;
`;

const Index = ({
  active,
  sidebarStatus,
  selectedFile,
  settingbarStatus,
  selectedfilename,
  selectedFiles,
  selectedId,
}) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [selectedItem] = useState("bounding-box");
  const [coordinateSaved, setCoordinatesSaved] = useState({});
  const [headingsSaved, setHeadingsSaved] = useState({});
  const [questionsSaved, setQuestionsSaved] = useState({});
  const [documenturl, setDocumentUrl] = useState("");
  const [anotationtype, setAnotationType] = useState();
  const [receivedPointsSaved, setReceivedPointsSaved] = useState({});
  const [selectedText, setSelectedText] = useState("");
  const [selectedTab, setSelectedTab] = useState("full_context");
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(true);

  let timeoutId = null;

  useEffect(() => {
    clearTimeout(timeoutId);

    if (selectedId) {
      setCoordinatesSaved({});
      setHeadingsSaved({});
      setQuestionsSaved({});
      setDocumentUrl(null);
    } else {
      setCoordinatesSaved({});
      setHeadingsSaved({});
      setQuestionsSaved({});
      setDocumentUrl(null);
    }
  }, [selectedId]);

  const handlePointsSavedChange = (newPointsSaved) => {
    // Update the state in the parent component
    setReceivedPointsSaved(newPointsSaved);
  };

  //get file download url
  const { data: preSignedData } = useQuery({
    queryKey: [`file-${selectedId}`, selectedId],
    queryFn: () => authDataObject.Admindownloadpdffile(selectedId),
    enabled: selectedId !== null && selectedId !== undefined,
  });
  useEffect(() => {
    if (selectedId && preSignedData) {
      const documentUrl = preSignedData?.data?.details?.pre_signed_url;
      setDocumentUrl(documentUrl);
    }
  }, [selectedId, preSignedData]);

  const [isBlocksReadyToFetch, setIsBlocksReadyToFetch] = useState(false);
  // get blocks data
  const { data: boundingBoxesData, isFetched: isBoundingBoxFetched } = useQuery(
    {
      queryKey: [`annotation-data-${selectedId}-blocks`, selectedId],
      queryFn: () =>
        authDataObject.getAnnotationData({
          document_id: selectedId,
          annotation_type: "blocks",
        }),
      enabled:
        selectedId !== null && selectedId !== undefined && isBlocksReadyToFetch,
      onSuccess: () => {
        setIsBlocksReadyToFetch(false);
      },
      onError: () => {
        setIsBlocksReadyToFetch(false);
      },
    }
  );

  useEffect(() => {
    if (selectedId && boundingBoxesData) {
      setCoordinatesSaved(boundingBoxesData?.data?.details?.response?.pages);
    }
  }, [selectedId, boundingBoxesData]);

  const [isHeadingsReadyToFetch, setIsHeadingsReadyToFetch] = useState(false);
  // get heading data
  const { data: headingData, isFetched: isHeadingsFetched } = useQuery({
    queryKey: [`annotation-data-${selectedId}-headings`, selectedId],
    queryFn: () => authDataObject.Adminpointdata(selectedId, "headings"),
    enabled:
      selectedId !== null && selectedId !== undefined && isHeadingsReadyToFetch,
    onSuccess: () => {
      setIsHeadingsReadyToFetch(false);
    },
    onError: () => {
      setIsHeadingsReadyToFetch(false);
    },
  });

  useEffect(() => {
    if (selectedId && headingData) {
      setHeadingsSaved(headingData?.data?.details?.response);
    }
  }, [selectedId, headingData]);

  const [isQuestionReadyToFetch, setIsQuestionReadyToFetch] = useState(false);
  // get question data
  const { data: questionData, isFetched: isQuestionsFetched } = useQuery({
    queryKey: [`annotation-data-${selectedId}-questions`, selectedId],
    queryFn: () => authDataObject.Adminpointdata(selectedId, "questions"),
    enabled:
      selectedId !== null && selectedId !== undefined && isQuestionReadyToFetch,
    onSuccess: () => {
      setIsQuestionReadyToFetch(false);
    },
    onError: () => {
      setIsQuestionReadyToFetch(false);
    },
  });

  useEffect(() => {
    if (selectedId && questionData) {
      setQuestionsSaved(questionData?.data?.details?.response);
    }
  }, [selectedId, questionData]);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleSelectedItemChange = (item) => {
  //   setSelectedItem(item);
  // };

  const handleKeyInfoClick = (text) => {
    setAnotationType(text);
  };

  const handleSelectedTextChange = (text) => {
    setSelectedText(text);
  };

  const handleSelectedTabChange = (tab) => {
    setSelectedTab(tab);
  };

  //insight pulse
  const [progress, setProgress] = useState(0);
  const [annotations, setAnnotations] = useState([]);
  const [annotationsBusiness, setAnnotationsBusiness] = useState([]);

  const { data: annotationProgressData, refetch } = useQuery({
    queryKey: [`insight-pulse-${selectedId}`, selectedId],
    queryFn: () => authDataObject.getInsightPulse(selectedId),
    enabled: selectedId !== null && selectedId !== undefined,
  });

  const startProgress = (completedProgress, lastProgress) => {
    let width = lastProgress ?? 0;
    // if (lastProgress === 100) return setProgress(100);
    const interval = setInterval(() => {
      if (completedProgress > width) {
        width += 2;
        setProgress(width);
      } else if (completedProgress === width) {
        clearInterval(interval);
      }
    }, 20);
  };

  useEffect(() => {
    // setProgress(0);
    if (selectedId && annotationProgressData?.data?.details?.annotations_list) {
      const insightTrueEssential =
        annotationProgressData?.data?.details?.annotations_list?.filter(
          (item) =>
            item?.is_insight === true &&
            item?.status === "completed" &&
            item?.insight_type === 1
        );

      const insightTrueBusiness =
        annotationProgressData?.data?.details?.annotations_list?.filter(
          (item) =>
            item?.is_insight === true &&
            item?.status === "completed" &&
            item?.insight_type === 2
        );

      const isQuestionReadyToFetch =
        annotationProgressData?.data?.details?.annotations_list?.find(
          (item) =>
            item?.annotation_type === "questions" &&
            (item?.status === "completed" ||
              item?.status === "failed" ||
              item?.status === "empty")
        );

      if (isQuestionReadyToFetch) {
        setIsQuestionReadyToFetch(true);
      }

      const isHeadingsReadyToFetch =
        annotationProgressData?.data?.details?.annotations_list?.find(
          (item) =>
            item?.annotation_type === "headings" &&
            (item?.status === "completed" ||
              item?.status === "failed" ||
              item?.status === "empty")
        );

      if (isHeadingsReadyToFetch) {
        setIsHeadingsReadyToFetch(true);
      }

      const isBlocksReadyToFetch =
        annotationProgressData?.data?.details?.annotations_list?.find(
          (item) =>
            item?.annotation_type === "blocks" &&
            (item?.status === "completed" ||
              item?.status === "failed" ||
              item?.status === "empty")
        );

      if (isBlocksReadyToFetch) {
        setIsBlocksReadyToFetch(true);
      }

      setAnnotations(insightTrueEssential);
      setAnnotationsBusiness(insightTrueBusiness);

      const [numerator, denominator] =
        annotationProgressData?.data?.details?.progress?.split("/");

      const currProgress =
        (parseInt(numerator, 10) / parseInt(denominator, 10)) * 100;

      const [numeratorInsight, denominatorInsight] =
        annotationProgressData?.data?.details?.insights_progress?.split("/");

      const currProgressInsight =
        (parseInt(numeratorInsight, 10) / parseInt(denominatorInsight, 10)) *
        100;

      startProgress(currProgressInsight, progress);

      if (currProgress < 100) {
        const timeoutId = setTimeout(() => {
          refetch();
        }, 10000);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [selectedId, annotationProgressData, refetch]);

  useEffect(() => {
    if (selectedId !== null && selectedId !== undefined) {
      setProgress(0);
      refetch();
    }
  }, [selectedId]);

  return (
    <IndexContainer
      active={active}
      sidebarStatus={sidebarStatus}
      settingbarStatus={settingbarStatus}
      windowWidth={windowWidth}
    >
      {/* <div className="main-container small-screen-container">
        {window.innerWidth > 770 ? (
          <div className="left-div">
            {selectedfilename?.document_type == ".txt" ||
            selectedfilename?.document_type == ".doc" ? (
              <>
                <TextViewerComponent
                  selectedfilevalename={selectedfilename}
                  documenturl={documenturl}
                  onSelectedTextChange={handleSelectedTextChange}
                />
              </>
            ) : (
              <>
                {selectedTab == "analyse_your_pick" ? (
                  <>
                    {" "}
                    <PdfViewerComponentSelect
                      onPointsSavedChange={handlePointsSavedChange}
                      selectedFile={selectedFile}
                      selectedfilevalename={selectedfilename}
                      selectedFiles={selectedFiles}
                      selectedItem={selectedItem}
                      coordinateSaved={coordinateSaved}
                      documenturl={documenturl}
                      selectedId={selectedId}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <PdfViewerComponent
                      selectedFile={selectedFile}
                      selectedfilevalename={selectedfilename}
                      selectedFiles={selectedFiles}
                      documenturl={documenturl}
                      onSelectedTextChange={handleSelectedTextChange}
                      selectedId={selectedId}
                    />{" "}
                  </>
                )}
              </>
            )}
          </div>
        ) : null}
        {window.innerWidth > 770 ? <div className="saprator-div"></div> : null}
        <div className="right-div">
          <ChatComponent
            selectedTab={selectedTab}
            headingsSaved={headingsSaved}
            questionsSaved={questionsSaved}
            selectedfilevalename={selectedfilename}
            onKeyInfoClick={(text) => handleKeyInfoClick(text)}
            selectedId={selectedId}
            receivedPointsSaved={receivedPointsSaved}
            selectedOption={selectedOption}
            selectedExportItem={selectedExportItem}
          />
        </div>
      </div> */}
      <div className="main-container large-screen-container">
        <SplitPane
          split="vertical"
          className={
            active && sidebarStatus && windowWidth > 770
              ? "custom-split-pane-active"
              : "custom-split-pane"
          }
          defaultSize="50%"
          minSize={0}
          maxSize={-650}
          resizerClassName="custom-resizer"
          pane2Style={{
            width: "48%",
            overflowX: "hidden",
          }}
        >
          {window.innerWidth > 770 ? (
            <div className="left-div">
              {selectedfilename?.document_type == ".txt" ||
              selectedfilename?.document_type == ".doc" ? (
                <TextViewerComponent
                  selectedfilevalename={selectedfilename}
                  documenturl={documenturl}
                  onSelectedTextChange={handleSelectedTextChange}
                  selectedId={selectedId}
                />
              ) : (
                <>
                  <PdfViewerComponentSelect
                    onPointsSavedChange={handlePointsSavedChange}
                    selectedFile={selectedFile}
                    selectedfilevalename={selectedfilename}
                    selectedFiles={selectedFiles}
                    selectedItem={selectedItem}
                    coordinateSaved={coordinateSaved}
                    documenturl={documenturl}
                    anotationtype={anotationtype}
                    selectedId={selectedId}
                  />
                </>
              )}
            </div>
          ) : null}
          <div className="right-div">
            <div className="tab-container">
              <TabsComponent
                onSelectedTabChange={handleSelectedTabChange}
                selectedId={selectedId}
                isChatResponseLoading={isLoaderActive}
              />
            </div>
            <ChatComponent
              selectedTab={selectedTab}
              headingsSaved={headingsSaved}
              questionsSaved={questionsSaved}
              selectedfilevalename={selectedfilename}
              onKeyInfoClick={(text) => handleKeyInfoClick(text)}
              selectedId={selectedId}
              selectedText={selectedText}
              selectedOption={selectedOption}
              documenturl={documenturl}
              annotations={annotations}
              annotationsBusiness={annotationsBusiness}
              isQuestionsFetched={isQuestionsFetched}
              isHeadingsFetched={isHeadingsFetched}
              isBoundingBoxFetched={isBoundingBoxFetched}
              isLoaderActive={isLoaderActive}
              setIsLoaderActive={(val) => {
                setIsLoaderActive(val);
              }}
            />
            <InsightPulse
              selectedId={selectedId}
              selectedFileName={selectedfilename?.file_name}
              annotations={annotations}
              annotationsBusiness={annotationsBusiness}
              progress={progress}
            />
          </div>
        </SplitPane>
      </div>
    </IndexContainer>
  );
};

export default Index;
