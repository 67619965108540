// import styled from 'styled-components';
// import { FaBars, FaCog } from 'react-icons/fa';

// export const Container = styled.div`
//   background-color: #FFF;
//   position: fixed;
//   height: 100%;
//   top: 65px;
//   transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out !important; /* Width and opacity transition */
//   // transition: width 0.8s ease-in-out !important;
//   // transition-duration: 0.8s !important;
//   width: ${props => (props.active ? (props.sidebarStatus ? '190px' : window.innerWidth <= 770 ? '0px' : '50px') : '0')};
//   animation: showSidebar 0.6s;
//   z-index: 0;
//   border-right: 1px solid rgb(226, 226, 226);
//   > img {
//     position: absolute;
//     color: #e2e2e2;
//     width: 20px;
//     height: 20px;
//     top: 22px;
//     left: 22px;
//     cursor: pointer;
//   }

//   @keyframes showSidebar {
//     from {
//       opacity: 0;
//       width: 0;
//     }
//     to {
//       opacity: 1;
//       width: ${props => (props.active ? (props.sidebarStatus ? '190px' : window.innerWidth <= 770 ? '0px' : '50px') : '0')};
//     }
//   }
// `;

// export const Content = styled.div`
// overflow: scroll !important;
// height : 100%;
// margin-top: 12px;
// animation: fadeIn 0.6s ease-in-out 4s forwards; /* Apply the animation after a 4s delay */
// `;

// export const SidebarIcon = styled(FaBars)`
// position: absolute;
//   width: 18px;
//   height: 18px;
//   top: 12px;
//   left: 13px;
//   cursor: pointer;
//   &:hover {
//     top: 9px;
//   left: 9px;
//     background-color: #E9E1E1; /* New background color on hover */
//     border-radius: 25%; /* Make the background semi-circular on hover */
//     width: 28px;
//     height: 28px;
//     padding: 6px
//   }
// `;

import styled from "styled-components";
import { FaBars } from "react-icons/fa";

export const Container = styled.div`
  background-color: #fff;
  position: fixed;
  height: 100%;
  top: 65px;
  transition: opacity 0.8s ease-in-out;
  width: ${(props) => (props.sidebarStatus ? "190px" : "50px")};
  z-index: 0;
  border-right: 1px solid rgb(226, 226, 226);
  > img {
    position: absolute;
    color: #e2e2e2;
    width: 20px;
    height: 20px;
    top: 22px;
    left: 22px;
    cursor: pointer;
  }
  animation: ${(props) => (props.sidebarStatus ? "showSidebar" : "hideSidebar")}
    0.2s;
  @keyframes showSidebar {
    from {
      width: 50px;
    }
    to {
      width: 190px;
    }
  }

  @keyframes hideSidebar {
    from {
      width: 190px;
    }
    to {
      width: 50px;
    }
  }
`;

export const Content = styled.div`
  overflow: scroll !important;
  height: 100%;
  margin-top: 12px;
`;

export const SidebarIcon = styled(FaBars)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12px;
  left: 18px;
  padding: 2px;
  margin-left: 2px;
  cursor: pointer;
  transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out !important; /* Width and opacity transition */
  &:hover {
    background-color: #e9e1e1; /* New background color on hover */
    border-radius: 5px; /* Make the background semi-circular on hover */
  }
`;
