// Import libraries
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

// Import icons
import { FaBars } from "react-icons/fa";
import DocDivelog from "../../assets/images/DocDivelogo_new.svg";

// Import CSS file for styling
import "./style.css";
import { Container, RightTabs, UploadButton } from "./styles";

// Import components
import Pricingmodal from "../Commoncomponent/Modals/Pricingmodal";
import Settingmodal from "../Commoncomponent/Modals/Settingmodal";
import Signinmodal from "../Commoncomponent/Modals/Signinmodal";
import ProfileModal from "../ProfileModal/ProfileModal";
import Sidebar from "../Sidebar";
import Index from "../layout/Index";

// import controller object for api calling
import LogoutConfirmModal from "../Commoncomponent/LogoutConfirmModal/LogoutConfirmModal";
import VerificationEmailModal from "../Commoncomponent/VerificationEmailModal/VerificationEmailModal";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Tooltip,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import authDataObject from "../../services/Auth_Controller/Auth_Controller";
import { formatDistance, parseISO } from "date-fns";
import ConfirmationAlertModal from "../ProfileModal/components/ConfirmationAlertModal";
import SubscriptionConfirm from "./SubscriptionConfirm";

const Home = () => {
  // State variables
  const [sidebar, setSidebar] = useState(true);
  const [settingbar, setSettingbar] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeComponent, setActiveComponent] = useState(null);
  const [documentid, setDocumentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [isModalpricingOpen, setIsModalpricingOpen] = useState(false); // Modal visibility state
  const [isModalpofileOpen, setIsModalprofileOpen] = useState(false); // Modal visibility state

  const [selectedFilesFromModal, setSelectedFilesFromModal] = useState([]);
  const [loginstatus, setLoginstatus] = useState(false);
  const [isgooglestatusStatusTrue, setIsGoogleLoginStatusTrue] =
    useState(false);
  const [isTabsDisabled, setIsTabsDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [currentUsage, setCurrentUsage] = useState();
  const [totalUsage, setTotalUsage] = useState(0);
  const { data: currUsage } = useQuery(
    [`current-usage-nav-bar`],
    () =>
      authDataObject.getCurrentUsage({
        detailed: false,
      }),
    {
      enabled: loginstatus,
    }
  );
  useEffect(() => {
    if (currUsage) {
      setCurrentUsage(currUsage?.data?.details);
    }
  }, [currUsage]);

  useEffect(() => {
    setTotalUsage(
      (currentUsage?.current_usage / currentUsage?.usage_limit) * 100
    );
  }, [currentUsage]);

  // Callback function to open the modal
  const handleOpenModal = () => {
    ReactGA.event({
      category: "User Interaction",
      action: "Click",
      label: "Get Started Button",
    });
    setIsModalOpen(true);
  };

  const navigate = useNavigate();
  useQuery({
    queryKey: [`user-validate`],
    queryFn: () => authDataObject.UserValidate(),
    enabled: localStorage.getItem("token") ? true : false,
    retry: 0,
    onError: (error) => {
      if (error.response.status > 400) {
        localStorage.clear();
        navigate("/");
        window.location.reload();
      }
    },
  });

  const handleOpenpricingModal = () => {
    setIsModalpricingOpen(true);
  };
  const handleOpenprofileModal = () => {
    setIsModalprofileOpen(true);
  };

  const handleSidebarItemClick = (component) => {
    setActiveComponent(component);
  };

  const handleSidebarIdClick = (id) => {
    setDocumentId(id);
  };

  const showSidebar = () => setSidebar(!sidebar);

  const phoneNumber = "9977159456"; // Replace with the actual phone number

  // Function to handle contact us click
  const handleContactUs = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      // Not a mobile device, provide a clickable link
      const phoneLink = document.createElement("a");
      phoneLink.href = `tel:${phoneNumber}`;
      phoneLink.click();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setLoginstatus(true);
    } else {
      setIsModalOpen(true);
    }

    // Function to handle window resize event
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [verificationLinkModalOpen, setVerificationLinkModalOpen] =
    useState(false);
  const [profileIcon] = useState(
    localStorage.getItem("UserUploadedImage") || null
  );

  // Function to toggle the profile dropdown
  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsLogoutModalOpen(true);
  };

  const handleContinueWithEmailClick = () => {
    if (localStorage.getItem("token")) {
      setLoginstatus(true);
    }
    setIsModalOpen(false);
  };

  const handleGoogleLoginStatusTrue = (status) => {
    setIsGoogleLoginStatusTrue(status);
  };

  const queryClient = useQueryClient();

  const onLogout = async () => {
    // Get the token from local storage
    const token = localStorage.getItem("token");

    // Immediate UI update before the API call
    window.location.reload();
    localStorage.clear();
    queryClient.clear();

    // Perform the logout API call in the background
    try {
      const res = await authDataObject.logout(token);
      if (res.status !== 200) {
        console.log("Failed to logout properly, but UI is updated.");
      }
    } catch (error) {
      console.log("Error in logout: ", error);
    }
  };
  ///?subscription=lite

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const plan = urlParams.get("subscription");
    console.log("Plan: ", plan, loginstatus);
    if (loginstatus && plan) {
      // Set the selected plan state if the plan parameter exists in the URL
      setSelectedPlan(plan);
      setShowConfirmation(true);
    } else {
      console.log("No plan selected");
      // navigate("/");
    }
  }, [loginstatus]);

  const [paymentUpdateAlert, setPaymentUpdateAlert] = useState({
    show: false,
    message: "",
  });
  const { mutate, isLoading } = useMutation(authDataObject.updateSubscription, {
    onSuccess: (data) => {
      setShowConfirmation(false);
      setPaymentUpdateAlert({
        show: true,
        message:
          data?.data?.message ??
          "Subscription updated successfully. Please refresh the page to see the changes.",
      });
    },
    onError: (error) => {
      setShowConfirmation(false);
      setPaymentUpdateAlert({
        show: true,
        message:
          error?.response?.data?.message ??
          "Something went wrong. Please try again later",
      });
    },
  });

  return (
    <>
      <Container>
        {windowWidth <= 770 && (
          <div className="FaBarsWrapper">
            <div className="FaBarsWrapper" onClick={showSidebar}>
              <FaBars className="FaBarsIcon" />
            </div>
          </div>
        )}
        <Sidebar
          loginstatus={loginstatus}
          active={setSidebar}
          openSidebar={showSidebar}
          sidebarStatus={sidebar}
          onItemClick={handleSidebarItemClick}
          onIdClick={handleSidebarIdClick}
          selectedFiles={selectedFilesFromModal}
          isStillUploading={isUploading} // Pass the status to Sidebar
          setIsStillUploading={(val) => {
            setIsUploading(val);
          }}
        />
        <img
          className="LogoImage"
          src={DocDivelog}
          alt="images"
          style={{ marginInlineStart: "12px" }}
        />
        {!loginstatus ? (
          <>
            <RightTabs
              style={{
                marginRight: settingbar ? "80px" : "80px",
                pointerEvents: isgooglestatusStatusTrue ? "none" : "",
              }}
              show={!sidebar}
            >
              <UploadButton onClick={handleOpenModal}>Get started</UploadButton>
              <div className="Tab" onClick={() => handleOpenpricingModal()}>
                Pricing
              </div>
              {windowWidth > 770 ? (
                <>
                  <div className="Tab">
                    <a
                      href="https://docudiveai.com/developer/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#000",
                      }}
                    >
                      <span>Developer</span>
                    </a>
                  </div>
                  <div className="Tab">
                    <a
                      href="https://docudiveai.com/contact-us/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#000",
                      }}
                    >
                      <span> Contact Us</span>
                    </a>
                  </div>
                  <div className="Tab">
                    <a
                      href="https://docudiveai.com/guide/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#000",
                      }}
                    >
                      <span>Guide</span>
                    </a>
                  </div>
                </>
              ) : null}
            </RightTabs>
          </>
        ) : (
          <>
            <RightTabs
              style={{
                marginRight: settingbar ? "80px" : "80px",
                pointerEvents: isTabsDisabled ? "none" : "",
              }}
              show={!sidebar}
            >
              {currentUsage && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: 30,
                    }}
                    id="last-update-tool-tip"
                  >
                    <span
                      className="subtitle3"
                      style={{
                        margin: 0,
                      }}
                    >
                      {`Current Usage : ${currentUsage?.current_usage?.toFixed(
                        0
                      )}/${currentUsage?.usage_limit?.toFixed(0)}`}{" "}
                      ({totalUsage?.toFixed(1)}%)
                    </span>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#d9d9d9",
                        height: 5,
                        borderRadius: 4,
                      }}
                    >
                      <div
                        style={{
                          width: `${totalUsage >= 100 ? 100 : totalUsage}%`,
                          backgroundColor:
                            totalUsage >= 100
                              ? "#ff4d4f"
                              : totalUsage >= 90
                              ? "#ffc107"
                              : "rgb(16, 11, 229)",

                          transition: "width 1s ease-in-out",
                          height: 5,
                          borderRadius: 4,
                        }}
                      ></div>
                    </div>
                  </div>
                  <Tooltip
                    isOpen={tooltipOpen}
                    target="last-update-tool-tip"
                    toggle={toggle}
                    style={{
                      backgroundColor: "#9c9b9b",
                      color: "white",
                      fontSize: 12,
                      width: 200,
                    }}
                  >
                    {`Last update ${formatDistance(
                      parseISO(`${currentUsage?.timestamp}+00`),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}`}
                  </Tooltip>
                </>
              )}

              <UploadButton onClick={handleOpenModal}>Upload</UploadButton>
              <div className="Tab" onClick={handleOpenpricingModal}>
                Upgrade
              </div>
              {windowWidth > 770 ? (
                <>
                  <div className="Tab">
                    <a
                      href="https://docudiveai.com/contact-us/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#000",
                      }}
                    >
                      <span> Contact Us</span>
                    </a>
                  </div>
                  <div className="Tab">
                    <a
                      href="https://docudiveai.com/guide/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#000",
                      }}
                    >
                      <span>Guide</span>
                    </a>
                  </div>
                </>
              ) : null}
            </RightTabs>

            <Dropdown
              className="dropdown-container-profile"
              isOpen={profileDropdownOpen}
              toggle={() => toggleProfileDropdown()}
            >
              <DropdownToggle caret className="toggle-profile-button">
                {localStorage.getItem("UserUploadedImage") ? (
                  <img
                    src={localStorage.getItem("UserUploadedImage")}
                    alt="user profile"
                    style={{
                      width: 38,
                      height: 38,
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      color: "#000",
                      textAlign: "center",
                      width: 38,
                      borderRadius: "50%",
                    }}
                  >
                    {localStorage.getItem("UserName")
                      ? localStorage.getItem("UserName")
                      : ""}
                  </div>
                )}
              </DropdownToggle>
              <DropdownMenu className="drop-down-menu-style">
                <DropdownItem className="disable-item" disabled>
                  {localStorage.getItem("UserEmail")}
                </DropdownItem>
                <div
                  onClick={handleOpenprofileModal}
                  className="drop-down-item-style"
                  style={{
                    margin: 4,
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: 500,
                    padding: "5px 10px",
                  }}
                >
                  Profile
                </div>
                <div
                  onClick={() => handleOpenpricingModal()}
                  className="drop-down-item-style"
                  style={{
                    margin: 4,
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: 500,
                    padding: "5px 10px",
                  }}
                >
                  Pricing
                </div>
                <div
                  className="drop-down-item-style"
                  style={{
                    margin: 4,
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: 500,
                    padding: "5px 10px",
                  }}
                >
                  {" "}
                  <a
                    href="https://docudiveai.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#000",
                    }}
                  >
                    Privacy
                  </a>
                </div>
                <div
                  className="drop-down-item-style"
                  style={{
                    margin: 4,
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: 500,
                    padding: "5px 10px",
                  }}
                >
                  {" "}
                  <a
                    href="https://docudiveai.com/terms/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#000",
                    }}
                  >
                    {" "}
                    Terms
                  </a>
                </div>
                <div
                  className="drop-down-item-style"
                  style={{
                    margin: 4,
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: 500,
                    padding: "5px 10px",
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </div>
              </DropdownMenu>
            </Dropdown>
          </>
        )}
      </Container>
      <Index
        active={sidebar || settingbar}
        selectedFile={selectedFile}
        sidebarStatus={sidebar}
        settingbarStatus={settingbar}
        selectedfilename={activeComponent}
        selectedFiles={selectedFilesFromModal}
        selectedId={documentid}
      />
      {loginstatus && (
        <Settingmodal
          isOpen={isModalOpen}
          toggleModal={() => setIsModalOpen(!isModalOpen)}
          setIsFileUploading={(val) => {
            setIsUploading(val);
          }}
        />
      )}

      {showConfirmation && (
        <SubscriptionConfirm
          isOpen={showConfirmation}
          handleClose={() => {
            setShowConfirmation(false);
            selectedPlan && setSelectedPlan(null);
            navigate("/");
          }}
          description={`Are you sure you want to upgrade to ${selectedPlan} plan?`}
          handleConfirm={() => {
            mutate(selectedPlan);
          }}
          isLoading={isLoading}
        />
      )}

      {!loginstatus && (
        <Signinmodal
          isOpen={isModalOpen}
          toggleModal={() => setIsModalOpen(!isModalOpen)}
          onContinueWithEmailClick={handleContinueWithEmailClick} // Pass the callback function
          onStatusTrue={handleGoogleLoginStatusTrue}
          onVerifyLinkClick={(val) => setVerificationLinkModalOpen(val)}
        />
      )}

      <Pricingmodal
        isOpen={isModalpricingOpen}
        toggleModal={() => setIsModalpricingOpen(!isModalpricingOpen)}
      />
      {paymentUpdateAlert?.show && (
        <ConfirmationAlertModal
          isOpen={paymentUpdateAlert?.show}
          handleClose={() => {
            setPaymentUpdateAlert({
              show: false,
              message: "",
            });
            navigate("/");
          }}
          title={paymentUpdateAlert?.message}
        />
      )}

      {isModalpofileOpen && (
        <ProfileModal
          isOpen={isModalpofileOpen}
          handleClose={(val) => setIsModalprofileOpen(val)}
        />
      )}
      {isLogoutModalOpen && (
        <LogoutConfirmModal
          isOpen={isLogoutModalOpen}
          handleClose={() => {
            setIsLogoutModalOpen(false);
          }}
          handleConfirm={() => {
            setIsTabsDisabled(true);
            onLogout();
          }}
        />
      )}
      {verificationLinkModalOpen && (
        <VerificationEmailModal
          isOpen={verificationLinkModalOpen}
          handleClose={() => {
            setVerificationLinkModalOpen(false);
          }}
        />
      )}

      {isgooglestatusStatusTrue ? (
        <div
          style={{
            height: "100vh",
            width: " 100%",
            position: "fixed",
            top: 65,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: "1050",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <Spinner
            size={"xl"}
            className="m-1 spinner-loader"
            color="secondary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: "102",
            }}
          />{" "}
          <span
            style={{
              color: "#6c757d",
            }}
          >
            Please wait...
          </span>
        </div>
      ) : null}
    </>
  );
};

export default Home;
