import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Modal, ModalBody, ModalHeader, Spinner, Tooltip } from "reactstrap";
import "./pulseModal.css";
import ExoprtIcon from "../../assets/images/export_file_icon.svg";
import authDataObject from "../../services/Auth_Controller/Auth_Controller";
import { PDFDownloadLink } from "@react-pdf/renderer";
import GenerateAnnotationPdf from "./GenerateAnnotationPdf";
import { useQuery } from "react-query";
import { RiFileCopyLine } from "react-icons/ri";
import MarkdownRenderer from "../../components/Commoncomponent/MarkdownRenderer";

export const PulseModal = ({
  isOpen,
  handleClose,
  annotationType,
  displayName,
  selectedId,
  selectedFileName,
}) => {
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const [showError, setShowError] = useState(false);

  const { data: annotationData, isFetching } = useQuery({
    queryKey: [
      `annotation-${selectedId}-${annotationType}`,
      selectedId,
      annotationType,
    ],
    queryFn: () =>
      authDataObject.getAnnotationData({
        document_id: selectedId,
        annotation_type: annotationType,
      }),
    enabled: annotationType !== null && selectedId !== null,
  });

  useEffect(() => {
    if (annotationData) {
      setDataList(filterData(annotationData?.data));
    }
  }, [annotationData]);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(dataList, null, 2));
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1000);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      size="xl"
      centered
      backdrop
      scrollable
      fade
    >
      <ModalHeader
        className="modal-header-custom"
        toggle={() => handleClose(false)}
      >
        <div className="modal-header-container">
          <div className="title-text">{displayName}</div>
          <div className="vert-line"></div>
          <div className="sub-title-text">
            {getSubTitleText(annotationType)}
          </div>
          <div className="action-container">
            <div className="vert-line"></div>
            <div className="button-container">
              <RiFileCopyLine
                id="copy-icon"
                size={20}
                color="#8d8a8a"
                cursor={"pointer"}
                className="copy-icon-pulse-modal"
                onClick={() => {
                  handleCopy();
                }}
              />

              {annotationType === "ner" ||
              annotationType === "info_snippets" ||
              annotationType === "headings" ? (
                <div>
                  {!isFetching ? (
                    <PDFDownloadLink
                      document={
                        <GenerateAnnotationPdf
                          dataList={dataList}
                          documentName={selectedFileName}
                          annotationType={annotationType}
                          displayName={displayName}
                        />
                      }
                      fileName={`${selectedFileName
                        ?.split(".")
                        .slice(0, -1)
                        .join(".")}-${displayName.replace(/ /g, "_")}.pdf`}
                      className="icon-text-container"
                    >
                      {({ blob, url, loading, error }) => (
                        // setShowError(error ? true : false),
                        <>
                          <span>
                            <img src={ExoprtIcon} alt="export icon" />
                          </span>
                          <span className="text">Export</span>
                        </>
                      )}
                    </PDFDownloadLink>
                  ) : (
                    <button className="icon-text-container" disabled={true}>
                      <span>
                        <Spinner
                          color="secondary"
                          size="sm"
                          style={{
                            borderWidth: "2px",
                          }}
                        />
                      </span>
                      <span className="text">Export</span>
                    </button>
                  )}
                </div>
              ) : (
                <button
                  className="icon-text-container"
                  onClick={async () => {
                    setIsLoading(true);
                    const res = await authDataObject.exportPDF({
                      document_id: selectedId,
                      annotation_type: annotationType,
                    });
                    if (res.status === 200) {
                      setIsLoading(false);
                      let url = res.data?.details?.presigned_url;
                      const link = document.createElement("a");
                      link.href = url;
                      link.download = `${selectedFileName
                        ?.split(".")
                        .slice(0, -1)
                        .join(".")}-${displayName.replace(/ /g, "_")}.pdf`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }
                  }}
                >
                  <span>
                    {isFetching || isLoading ? (
                      <Spinner
                        color="secondary"
                        size="sm"
                        style={{
                          borderWidth: "2px",
                        }}
                      />
                    ) : (
                      <img src={ExoprtIcon} alt="export icon" />
                    )}
                  </span>
                  <span className="text">Export</span>
                </button>
              )}

              <button
                className="close-button-pulse-modal"
                onClick={() => handleClose(false)}
              >
                <FaTimes className="close-button-icon" />
              </button>
            </div>
          </div>
        </div>
        <Tooltip
          isOpen={tooltipOpen}
          target="copy-icon"
          style={{
            backgroundColor: "#9c9b9b",
            color: "white",
            maxWidth: "1500px",
          }}
        >
          Copied!
        </Tooltip>
      </ModalHeader>
      <ModalBody
        style={{
          maxHeight: "calc(100vh - 150px)",
          overflowY: "auto",
          marginRight: "2px",
        }}
      >
        <div
          className="modal-body-container"
          style={{
            justifyContent: isFetching ? "center" : "flex-start",
          }}
        >
          {isFetching ? (
            <Spinner
              color="secondary"
              size={60}
              style={{
                borderWidth: "2px",
              }}
            />
          ) : (
            <div
              className={`${
                annotationType === "ner" || annotationType === "info_snippets"
                  ? "ner-grid"
                  : "modal-body-ul"
              }`}
            >
              {!isFetching && renderList(dataList, annotationType)}
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

const getSubTitleText = (type) => {
  switch (type) {
    case "timeline":
      return "Following are the extracted timeline from the document";
    case "summary":
      return "Following is the extracted summary from the document";
    case "headings":
      return "Following are the extracted headings and their corresponding summaries";
    case "ner":
      return "Following are the extracted Named Entities from the document";
    case "citations":
      return "Following are the extracted citations from the document";
    case "cited_examples":
      return "Following are the extracted examples cited in the document";
    case "topics":
      return "Following are the extracted topics and their corresponding summary";
    case "info_snippets":
      return "Following are the extracted information from the document";
    case "financial_info":
      return "Following are the extracted financial information from the document";
    case "educational_info":
      return "Following are the extracted educational information";
    case "editorial_info":
      return "Following are the extracted editorial information from the document";
    case "legal_info":
      return "Following are the extracted legal information from the document";
    default:
      return null;
  }
};

const filterData = (data) => {
  switch (data?.details?.annotation_type) {
    case "timeline":
      return data.details.response;
    case "summary":
      return data.details.response?.summary;
    case "headings":
      return data.details.response;
    case "ner":
      return data?.details?.response;
    case "citations":
      return data?.details?.response;
    case "cited_examples":
      return data?.details?.response;
    case "topics":
      return data?.details?.response;
    case "info_snippets":
      return data?.details?.response;
    case "financial_info":
      return data?.details?.response;
    case "educational_info":
      return data?.details?.response;
    case "editorial_info":
      return data?.details?.response;
    case "legal_info":
      return data?.details?.response;
    default:
      console.log("default", data);
  }
};

const renderList = (data, type) => {
  switch (type) {
    case "timeline":
      return getTimelineDataList(data);
    case "summary":
      return getSummaryDataList(data);
    case "citations":
      return getCitationsList(data);
    case "cited_examples":
      return getCitedExampleList(data);
    case "topics":
      return getTopicsList(data);
    case "financial_info":
      return getBusinessInfo(data);
    case "educational_info":
      return getBusinessInfo(data);
    case "editorial_info":
      return getBusinessInfo(data);
    case "legal_info":
      return getBusinessInfo(data);
    // in FE
    case "headings":
      return getHeadingDataList(data);
    case "info_snippets":
      return getInfoSnippets(data);
    case "ner":
      return getNERDataList(data);
    default:
      return null;
  }
};

//Timeline
const getTimelineDataList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )

    // data &&
    // data?.map((item, index) => {
    //   return (
    //     <li key={index} className="timeline-li">
    //       <span className="timeline-s">{item?.time} : </span>
    //       <span className="timeline-t">{item?.summary}</span>
    //     </li>
    //   );
    // })
  );
};

//Key Info
const getSummaryDataList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // <div className="summary-li">
    //   <span className="summary-text">{data ?? ""} </span>
    // </div>
  );
};

//Label Brief
const getHeadingDataList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <li key={index} className="heading-li">
          <span className="heading-h">{item?.heading}</span>
          <br />
          <span className="heading-s">{item?.summary}</span>
        </li>
      );
    })
  );
};

//Named Entities
const getNERDataList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <div key={index} className="ner-box">
          <span className="ner-text">{item.display_name} </span>
          <hr />
          <div className="ner-sub-box">
            {item?.entities?.map((subItem, subIndex) => {
              return (
                <span key={subIndex} className="ner-sub-text">
                  {subItem}
                </span>
              );
            })}
          </div>
        </div>
      );
    })
  );
};

//Citations
const getCitationsList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // data &&
    // data?.map((item, index) => {
    //   return (
    //     <div key={index} className="citation-box">
    //       <span className="citation-d">{item?.document} </span>
    //       <span className="citation-s">{item?.section}</span>
    //     </div>
    //   );
    // })
  );
};

//Cited Examples
const getCitedExampleList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // data &&
    // data?.map((item, index) => {
    //   return (
    //     <div key={index} className="citation-box">
    //       <span className="citation-d">{item?.term} : </span>
    //       <span className="citation-s">{item?.example}</span>
    //     </div>
    //   );
    // })
  );
};

//Topics
const getTopicsList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // data &&
    // data?.map((item, index) => {
    //   return (
    //     <div key={index} className="citation-box">
    //       <span className="citation-d">{item?.topic} </span>
    //       <span className="citation-s">{item?.description}</span>
    //     </div>
    //   );
    // })
  );
};

//Info Snippets
const getInfoSnippets = (data) => {
  return (
    data &&
    Object.keys(data)?.map((key, index) => {
      if (data[key]?.length === 0) return null;
      return (
        <div key={index} className="ner-box">
          <span className="ner-text">{key} </span>
          <hr />
          <div className="ner-sub-box">
            {data[key] &&
              data[key]?.map((item, subIndex) => {
                return (
                  <span key={subIndex} className="ner-sub-text">
                    {item}
                  </span>
                );
              })}
          </div>
        </div>
      );
    })
  );
};

//business insights
const getBusinessInfo = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // Object.keys(data).map((key, index) => {
    //   if (data[key]?.length === 0) return null;
    //   return (
    //     <div key={index} className="bus-info-box">
    //       <span className="ner-text">{key} </span>
    //       <hr />
    //       <div className="bus-detail-box">
    //         {data[key]?.map((note, subIndex) => {
    //           return (
    //             <div key={subIndex} className="citation-box">
    //               <span className="citation-d"> {note?.item} : </span>
    //               <span className="citation-s">{note?.term_description}</span>
    //             </div>
    //           );
    //         })}
    //       </div>
    //     </div>
    //   );
    // })
  );
};
