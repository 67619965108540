import { Bar } from "react-chartjs-2";
import authDataObject from "../../../services/Auth_Controller/Auth_Controller";
import { useState } from "react";
import { useEffect } from "react";
import MonthPicker from "../../MonthPicker/MonthPicker";
import { Spinner, Tooltip } from "reactstrap";
import {
  endOfMonth,
  format,
  formatDistance,
  parseISO,
  startOfMonth,
} from "date-fns";
import { useQuery } from "react-query";
import { CiCircleInfo } from "react-icons/ci";

const Usage = () => {
  const [userUsage, setUserUsage] = useState();
  const [currentUsage, setCurrentUsage] = useState();
  const [totalUsage, setTotalUsage] = useState(0);
  const [dateRang, setDateRang] = useState({
    from_date: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to_date: format(endOfMonth(new Date()), "yyyy-MM-dd"),
  });
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const {
    data: usageData,
    isFetching,
    refetch,
  } = useQuery([`usage-${dateRang}`, dateRang], () =>
    authDataObject.getUsage(dateRang)
  );

  const {
    data: currUsage,
    isFetching: isFetchingCurrUsage,
    refetch: refetchCurrUsage,
  } = useQuery([`current-usage`], () =>
    authDataObject.getCurrentUsage({
      detailed: true,
    })
  );
  const shouldShowSpinner =
    !initialFetchComplete && (isFetching || isFetchingCurrUsage);
  useEffect(() => {
    refetch();
    refetchCurrUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFetching && !initialFetchComplete && !isFetchingCurrUsage) {
      setInitialFetchComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isFetchingCurrUsage]);

  // Reset initialFetchComplete when component unmounts
  useEffect(() => {
    return () => {
      setInitialFetchComplete(false);
    };
  }, []);

  useEffect(() => {
    setTotalUsage(
      (currentUsage?.current_usage / currentUsage?.usage_limit) * 100
    );
  }, [currentUsage]);

  useEffect(() => {
    if (usageData && currUsage) {
      setUserUsage(usageData?.data?.details);
      setCurrentUsage(currUsage?.data?.details);
    }
  }, [usageData, currUsage]);

  const options = {
    plugins: {
      title: {
        display: false,
        text: "Usage",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels: userUsage?.usage_dict ? Object.keys(userUsage?.usage_dict) : [],
    datasets: [
      {
        label: "Chats",
        data: userUsage?.usage_dict
          ? Object.values(userUsage?.usage_dict).map((data) => data.chats)
          : [],
        backgroundColor: "rgb(16, 11, 229)",
        borderColor: "rgb(16, 11, 229)",
        borderWidth: 1,
      },
      {
        label: "Pages",
        data: userUsage?.usage_dict
          ? Object.values(userUsage?.usage_dict).map((data) => data.pages)
          : [],
        backgroundColor: "#00ffff",
        borderColor: "#00ffff",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      {shouldShowSpinner && (
        <div
          style={{
            height: "85%",
            width: "90%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: "1050",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <Spinner
            size={"xl"}
            className="m-1 spinner-loader"
            color="secondary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: "102",
            }}
          />{" "}
          <span
            style={{
              color: "#6c757d",
            }}
          >
            Please wait...
          </span>
        </div>
      )}
      <h1 className="subtitle">Usage</h1>
      <div style={{ marginTop: 8 }}>
        <span className="belowstyle">
          Below you'll find a summary of usage for your profile. All dates and
          times are UTC-based, and data may be delayed up to 5 minutes.
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        <MonthPicker
          onMonthChange={(month) => {
            setDateRang({
              from_date: format(startOfMonth(new Date(month)), "yyyy-MM-dd"),
              to_date: format(endOfMonth(new Date(month)), "yyyy-MM-dd"),
            });
          }}
        />
        {/* {userUsage?.from_date && (
          <div>
            <span className="belowstyle">
              {`Usage (${format(parseISO(userUsage?.from_date), "LLLL")}) : ${
                userUsage?.total_usage
              }`}
            </span>
          </div>
        )} */}
      </div>

      <div
        style={{
          height: 310,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "65%",
        }}
      >
        <Bar type options={options} data={data} />
        {userUsage && (
          <span
            className="belowstyle"
            style={{
              marginTop: 4,
              alignSelf: "center",
            }}
          >
            {`Last update ${formatDistance(
              parseISO(`${userUsage.timestamp}+00`),
              new Date(),
              {
                addSuffix: true,
              }
            )}`}
            &nbsp; {"-"} &nbsp;
            {`Total ${userUsage?.total_usage} credits used in ${format(
              parseISO(userUsage?.from_date),
              "LLLL"
            )}`}
          </span>
        )}
      </div>

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 40,
            alignItems: "center",
            gap: 10,
          }}
        >
          <h1 className="subtitle2">{`Current Usage`}</h1>
          {currentUsage && (
            <>
              <div id="info-tool-tip">
                <span
                  className="belowstyle"
                  style={{
                    marginTop: 4,
                  }}
                >
                  {`From
            ${format(parseISO(currentUsage?.usage_start_time), "yyyy-MM-dd")} to
            ${format(parseISO(currentUsage?.usage_end_time), "yyyy-MM-dd")}
            `}
                </span>
                <CiCircleInfo fontSize={13} />
              </div>
              <Tooltip
                isOpen={tooltipOpen}
                target="info-tool-tip"
                toggle={toggle}
                style={{
                  backgroundColor: "#9c9b9b",
                  color: "white",
                  maxWidth: "1500px",
                }}
              >
                <ul>
                  {Array.isArray(currentUsage?.detailed_list) &&
                    currentUsage?.detailed_list?.map((val, i) => {
                      return (
                        <li
                          style={{
                            textAlign: "start",
                          }}
                          key={i}
                        >
                          {`${val?.subscription_name} From ${format(
                            parseISO(val?.subscription_start_date),
                            "yyyy-MM-dd"
                          )} to ${format(
                            parseISO(val?.subscription_end_date),
                            "yyyy-MM-dd"
                          )} ${" - "} ${val?.used_credits}/${
                            val?.subscription_credits
                          } 
                          (${(
                            (val?.used_credits / val?.subscription_credits) *
                            100
                          )?.toFixed(1)}%)`}
                        </li>
                      );
                    })}
                </ul>
              </Tooltip>
            </>
          )}
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "start" }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "#d9d9d9",
              height: 17,
              borderRadius: 4,
              marginBottom: 20,
              marginTop: 5,
            }}
          >
            <div
              style={{
                width: `${totalUsage >= 100 ? 100 : totalUsage}%`,
                backgroundColor:
                  totalUsage >= 100
                    ? "#ff4d4f"
                    : totalUsage >= 90
                    ? "#ffc107"
                    : "rgb(16, 11, 229)",
                height: 17,
                borderRadius: 2,
                transition: "width 1s ease-in-out",
              }}
            ></div>
          </div>
          {currentUsage?.current_usage && currentUsage?.usage_limit ? (
            <span className="subtitle3">
              {`${currentUsage?.current_usage?.toFixed(
                0
              )}/${currentUsage?.usage_limit?.toFixed(0)}`}{" "}
              ({totalUsage?.toFixed(1)}%)
            </span>
          ) : (
            <span className="subtitle3">{`0/${currentUsage?.usage_limit?.toFixed(
              0
            )}`}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Usage;
